<template>
  <!-- @vue-ignore -->
  <Swiper
    v-if="defaultSlot"
    v-bind="swiperOptions"
    class="ui-swiper"
    @init="swiperInit"
  >
    <slot />
  </Swiper>
</template>

<script setup lang="ts">
import { defu } from 'defu'
import type SwiperClass from 'swiper'
import 'swiper/css'
import type { SwiperOptions } from 'swiper/types'
import { Swiper } from 'swiper/vue'
import { computed, onBeforeUnmount, ref, useSlots } from 'vue'

import useSwiperSSRStyles from '~ui/composables/useSwiperSSRStyles'

const props = defineProps<{ options?: SwiperOptions }>()

const swiperOptions = computed((): SwiperOptions => defu({}, props.options))

useSwiperSSRStyles(swiperOptions.value)

const slots = useSlots()
const defaultSlot = slots?.default

const swiper = ref<SwiperClass | undefined>(undefined)

const swiperInit = (swiperClass: SwiperClass) => swiper.value = swiperClass
onBeforeUnmount(() => swiper.value?.destroy())
</script>
